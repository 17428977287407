.preloader{
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	background: url("../../assets/logo.svg") center/auto no-repeat #101010;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;		
	&.fade{
		opacity: 0;
		transition: 2s all ease-in-out;
	}
}