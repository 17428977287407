.font-montserrat {
  font-family: 'Montserrat';
}

.font-rostov {
  font-family: 'Rostov';
}

.black {
  color: var(--black);
}

.black2 {
  color: var(--black2);
}

.gray {
  color: var(--gray1);
}

.gray2 {
  color: var(--gray3);
}

.gray3 {
  color: var(--gray4);
}

.white {
  color: var(--offwhite);
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.w-300{
 font-weight: 300; 
 line-height: normal;
}

.w-400{
 font-weight: 400; 
 line-height: normal;
}

.w-500{
 font-weight: 500; 
 line-height: normal;
}

.w-600{
 font-weight: 600; 
 line-height: normal;
}

.w-700{
 font-weight: 700;
 line-height: normal; 
}