.canvas {
  border-radius: '40px';
  z-index: 20;
}

.model {
  background: radial-gradient(50% 50% at 50% 50%, #c83540 0%, #c1000e 100%);
  width: 530px;
  height: 530px;
  flex-shrink: 0;
  border-radius: 40px;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}