.overlay{
	width: 100vw;
	height: 100vh;
	padding: 24px;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20000;
	background-color: rgba(0,0,0,0.8);
}

.hide{
	opacity:0;
}

.modal{
	max-width: 1088px;
	background-color: #E1E1E1;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 32px;	
	&>:nth-last-child(n+2){
		margin-bottom: 24px;
	}
}

.close{
	width: 56px;
	height: 56px;
	background: url('../../assets/close.svg') center/cover no-repeat;
	cursor: pointer; 
	align-self: flex-end;
}

.bottom{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	&>:first-child{
		width: 25%;
	}
	&>:last-child{
		width: 75%;
	}	
	&>:nth-last-child(n+2){
		margin-right: 24px;
	}	
}

.wrap_all{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	&>:nth-child(n+1){
		flex-direction: row;		
	}
	&>:first-child{
		margin-bottom: 24px;		
	}
}

.wrap_inputs{
	display: flex;
	width: 100%;
	&>:first-child{
		margin-right: 24px;
	}
	&>:nth-child(n+1){
		width: 50%;		
	}	
}

.wrap_btns{
	display: flex;
	width: 100%;
	&>:first-child{
		margin-right: 24px;
	}
	&>:nth-child(n+1){
		width: 50%;		
	}		
}

.wrap_btn{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

@media screen and (max-width: 1136px) and (min-width: 744px){
	.bottom{
		flex-direction: column;
		&>:first-child{
			width: 100%;
			margin-bottom: 24px;
			max-width: 480px;
		}
		&>:last-child{
			width: 100%;
		}	
		&>:nth-last-child(n+2){
			margin-right: 0px;
		}		
	}	
}

@media screen and (max-width: 743px){
	.bottom{
		flex-direction: column;
		&>:first-child{
			width: 100%;
			margin-bottom: 24px;
			max-width: 480px;
		}
		&>:last-child{
			width: 100%;
		}	
		&>:nth-last-child(n+2){
			margin-right: 0px;
		}		
	}

	.wrap_all{
		&>:nth-child(n+1){
			flex-direction: column;		
		}	
	}

	.wrap_inputs, .wrap_btns{
		&>:nth-child(n+1){
			width: 100%;		
		}

		&>:nth-last-child(n+2){
			margin-bottom: 24px;		
		}				
	}
}