.main_wrap
	width: 100%
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

.input_wrap
	width: 100%
	height: 47px
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: center
	border-radius: 8px
	border: 1px solid var(--gray-3, #898989)
	background: var(--off-white, #EEE)	
	margin-bottom: 4px
	padding: 0 16px 0 16px
	&>:nth-child(n+2)
		margin-left: 16px
	&.error
		border-color: #FF2630

.input
	width: 100%
	outline: none
	border: none
	height: 30px
	padding: 0
	background-color: rgba(0,0,0,0) !important
	color: #101010 !important
	font-family: Montserrat
	font-size: 16px
	font-style: normal
	font-weight: 400
	line-height: normal	
	&::placeholder
		color: #101010
		font-family: Montserrat
		font-size: 16px
		font-style: normal
		font-weight: 400
		line-height: normal
	&:focus::placeholder
		opacity: 0
	&:-webkit-autofill
		transition: all 5000s ease-in-out



.eye
	cursor: pointer

		 

.wrap_err
	width: 100%
	height: 16px
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: center
	&>:nth-child(2)
		margin-left: 8px

.message_err
	color: #FF2630
	font-family: Montserrat
	font-size: 12px
	font-style: normal
	font-weight: 400
	line-height: 12px

