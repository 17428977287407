.btn{
  border-radius: 8px;
  padding: 6px 24px;
  transition: all 0.5s;
  width: fit-content; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    border: 2px solid rgba(148, 148, 148, 1);
  }  
}

.dark{
    background: #101010;
    border: 2px solid rgba(148, 148, 148, 0.3);
    border-radius: 8px;

    &:hover >:nth-child(n+1) { 
      transition: 0.5s;     
      color: #00B40F;          
    }
    &>:nth-child(n+1) {
      color: #fff;
      transition: 0.5s;
    }

    &:hover {
        border: 2px solid #00B40F;
      }
}

.light{
    background: #eee;
    border: 2px solid rgba(148, 148, 148, 0.3);
    &:hover >:nth-child(n+1) { 
      transition: 0.5s;     
      color: #fff;          
    }
    &>:nth-child(n+1) {
      color: #000;
      transition: 0.5s;
    }
    &:hover {
        background-color: #00B40F;
    }
}