a{
	text-decoration: none;
}

.wrap_all{
	width: max-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.wrap_screens{
	width: max-content;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	&>:first-child{
		margin-right: 24px;
	}		
}

.right_screen{
	width: 530px;
}

.left_screen{
	width: max-content;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	&>:nth-last-child(n+2){
		margin-bottom: 16px;
	}
}

.map {
	width: 530px;
	height: 530px;
	border-radius: 40px;
	overflow: hidden;
}

.whitespacetop{
	white-space: pre-wrap;
	margin-top: 16px;
}

.whitespace{
	white-space: pre-line;
}

.contact {
  position: relative;
  z-index: 1000;
  padding: 32px 0;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    background: var(--offwhite);
    border-radius: 8px;
    transition: all 0.5s;

    &__bottom {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
    }

    p {
      text-align: right;
    }

    h1, p, path {
      transition: all 0.5s;
    }

    &:hover {
      background: var(--red);

      h1, p {
        color: #eee;
      }

      svg {
        path {
          fill: #eee
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 16px 0 16px 0;
  }
}



@media screen and (max-width: 1136px) and (min-width: 744px){
  .map{
    width: calc(336px + (100vw - 744px) / 2.0206 );
    height: calc(336px + (100vw - 744px) / 2.0206 );
  } 
  .wrap_screens > :last-child{
    width: calc(336px + (100vw - 744px) / 2.0206 );
  }
}

/*@media screen and (max-width: 743px){
  .map{
    width: calc(304px + (100vw - 320px) / 1.8716 );
    height: calc(304px + (100vw - 320px) / 1.8716 );
  } 
  .wrap_screens > :last-child{
    width: calc(304px + (100vw - 320px) / 1.8716 );
  }
  .wrap_screens{
    flex-direction: column;
    align-items: flex-start;
  }
  .wrap_screens>:first-child{
    margin-bottom: 16px;
    margin-right: 0px;
  } 
  .right_screen>:nth-last-child(n+2){
    margin-bottom: 16px;
  }  
}*/

@media screen and (max-width: 743px){
  .wrap_screens{
    flex-direction: column;
    align-items: flex-start;
  }
  .wrap_screens>:first-child{
    margin-bottom: 16px;
    margin-right: 0px;
  } 
  .right_screen>:nth-last-child(n+2){
    margin-bottom: 16px;
  }

}

@media screen and (max-width: 567px){
  .map{
    width: calc(304px + (100vw - 320px) / 1.0929 );
    height: calc(304px + (100vw - 320px) / 1.0929 );
  } 
  .wrap_screens > :last-child{
    width: calc(304px + (100vw - 320px) / 1.0929 );
  }  
}

@media screen and (max-width: 1039px){
	.contact__body{
		display: flex;
		flex-direction: column;	
		grid-template-columns: none;		
		margin: 0;		
	}
}

