.header{
	width: 100%;
	padding: 24px;	
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.logo_container{
	width: 236px;
	height: 56px;
	background: url("../../assets/logo.svg") left/auto no-repeat transparent;
}