.wrap_checkbox
	width: 100%
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: center
	cursor: pointer
	&>:first-child
		margin-right: 16px

.outer
	flex-shrink: 0
	display: flex
	align-items: center
	justify-content: center
	background: transparent
	width: 20px
	height: 20px
	border: 2px solid #00B40F
	border-radius: 4px
	&.error
		border-color: #FF2630

.inner
	width: 12px
	height: 12px
	flex-shrink: 0
	border-radius: 2px
	transition: all 0.5s
	background: transparent
	&.checked
		background: #00B40F	


.wrap_err
	width: 100%
	height: 16px
	display: flex
	flex-direction: row
	justify-content: flex-start
	align-items: center
	&>:nth-child(2)
		margin-left: 8px

.message_err
	color: #FF2630
	font-family: Montserrat
	font-size: 12px
	font-style: normal
	font-weight: 400
	line-height: 12px