.promo{
	width: max-content;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	&>:first-child{
		margin-right: 24px;
	}
}

.rightblock{
	width: 530px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.wrap_title{
	position: relative;
}

.up_text{
    opacity: 0.8;
    position: absolute;
    top: -20px;
    left: -10px;    
}

.center_text{
    position: relative;
    z-index: 1;   
}

.down_text{
    opacity: 0.8;
    position: absolute;	
    bottom: -15px;
    right: -20px;    
}

.subtitle{
    margin-top: 5px;
    margin-bottom: 20px;	
}

.desc{
    max-width: 415px;
    padding-bottom: 20px;	
}

@media screen and (max-width: 1136px) and (min-width: 744px){
  .promo > :first-child{
    width: calc(336px + (100vw - 744px) / 2.0206 );
    height: calc(336px + (100vw - 744px) / 2.0206 );
  } 
  .promo > :last-child{
    width: calc(336px + (100vw - 744px) / 2.0206 );
  }

}

@media screen and (max-width: 567px){
  .promo > :first-child{
    width: calc(304px + (100vw - 320px) / 1.0929 );
    height: calc(304px + (100vw - 320px) / 1.0929 );
  } 
  .promo > :last-child{
    width: calc(304px + (100vw - 320px) / 1.0929 );
  }

}

@media screen and (max-width: 743px){
    .promo{
        flex-direction: column;
        &>:first-child{
            margin-right: 0;
            margin-bottom: 24px;
        }        
    }
}



