.footer{
	max-width: 1084px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	&>:nth-last-child(n+2){
		margin-bottom: 40px;
	}
}

.row{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.bottom_row{
	width: max-content;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	align-self: center;
	flex-shrink: 0;
	&>:nth-last-child(n+2){
		margin-right: 20px;
		flex-shrink: 0;
	}

}

.col{
	width: max-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	&>:nth-last-child(n+2){
		margin-bottom: 20px;
	}	
}
.logo{
	width: 237px;
	height: 56px;
	background: url("../../assets/logo.svg") center/cover no-repeat #101010;	
}

  .grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

@media screen and (max-width: 1095px){
	.footer >.bottom_row{
		flex-direction: column;
		width: 100%;		
	}	
	.footer >.bottom_row >.bottom_row{
		justify-content: space-between;
		margin-bottom: 20px;
		width: 100%;
	}
}


@media screen and (max-width: 1136px) and (min-width: 744px){
  .logo{
    width: calc(171px + (100vw - 744px) / 5.9393 );
    height: calc(40px + (100vw - 744px) / 24.5 );
  } 
}

@media screen and (max-width: 743px){
  .logo{
    width: 171px;
    height: 40px;
  } 

  .footer{
  	max-width: 530px;
  }

  .row{
  	flex-direction: column;
  	justify-content: flex-start;
  }

  .footer >.bottom_row >.bottom_row{
  	flex-direction: column;
  }

  .bottom_row>:nth-last-child(n+2){
		margin-right: 0;
		margin-bottom: 24px;
	} 
}

@media screen and (min-width: 1136px){
		.footer{
			padding: 120px 0 120px 0;
		}		
	}


@media screen and (min-width: 744px){
	.footer{
		padding: 80px 0 80px 0;
	}
}

@media screen and (max-width: 743px){
	.footer{
		padding: 60px 0 60px 0;
	}
}